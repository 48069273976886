import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Company } from '../store/company/company.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  constructor(private http: HttpClient) {}

  getAllCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(environment.apiRoutes.companies);
  }

  getCompany(id: string): Observable<Company> {
    return this.http.get<Company>(`${environment.apiRoutes.companies}${id}`);
  }

  getReport(id: number): Observable<Company> {
    return this.http.get<Company>(`${environment.apiRoutes.companies}report/${id}`);
  }

  createCompany(company: Company): Observable<Company> {
    return this.http.post<Company>(environment.apiRoutes.companies, company);
  }

  updateCompany(company: Company): Observable<Company> {
    return this.http.patch<Company>(`${environment.apiRoutes.companies}${company.id}`, company);
  }

  deleteCompany(id: number): Observable<Company> {
    return this.http.delete<Company>(`${environment.apiRoutes.companies}${id}`);
  }
}
