import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InductionLocation } from '../store/induction-location/induction-location.model';

@Injectable({
  providedIn: 'root'
})
export class InductionLocationApiService {
  constructor(private http: HttpClient) {}

  getAllInductionLocations(): Observable<InductionLocation[]> {
    return this.http.get<InductionLocation[]>(environment.apiRoutes.inductionLocations);
  }

  getInductionLocation(id: number): Observable<InductionLocation> {
    return this.http.get<InductionLocation>(`${environment.apiRoutes.inductionLocations}${id}`);
  }

  createInductionLocation(inductionLocation: InductionLocation): Observable<InductionLocation> {
    return this.http.post<InductionLocation>(environment.apiRoutes.inductionLocations, inductionLocation);
  }

  updateInductionLocation(inductionLocation: InductionLocation): Observable<InductionLocation> {
    return this.http.patch<InductionLocation>(`${environment.apiRoutes.inductionLocations}${inductionLocation.id}`, inductionLocation);
  }

  deleteInductionLocation(id: number): Observable<InductionLocation> {
    return this.http.delete<InductionLocation>(`${environment.apiRoutes.inductionLocations}${id}`);
  }
}
