import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Program } from '../store/program/program.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProgramApiService {
  constructor(private http: HttpClient) {}

  getAllPrograms(): Observable<Program[]> {
    return this.http.get<Program[]>(environment.apiRoutes.programs);
  }

  getProgram(id: string): Observable<Program> {
    return this.http.get<Program>(`${environment.apiRoutes.programs}${id}`);
  }

  createProgram(program: Program): Observable<Program> {
    return this.http.post<Program>(environment.apiRoutes.programs, program);
  }

  updateProgram(program: Program): Observable<Program> {
    return this.http.patch<Program>(`${environment.apiRoutes.programs}${program.id}`, program);
  }

  deleteProgram(id: number): Observable<Program> {
    return this.http.delete<Program>(`${environment.apiRoutes.programs}${id}`);
  }
}
