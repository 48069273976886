import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouteMap } from '../store/route-map/route-map.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouteMapApiService {
  constructor(private http: HttpClient) {}

  getAllRouteMaps(): Observable<RouteMap[]> {
    return this.http.get<RouteMap[]>(environment.apiRoutes.routeMaps);
  }

  getRouteMap(id: number): Observable<RouteMap> {
    return this.http.get<RouteMap>(`${environment.apiRoutes.routeMaps}${id}`);
  }

  createRouteMap(map: RouteMap): Observable<RouteMap> {
    return this.http.post<RouteMap>(environment.apiRoutes.routeMaps, map);
  }

  updateRouteMap(map: RouteMap): Observable<RouteMap> {
    return this.http.patch<RouteMap>(`${environment.apiRoutes.routeMaps}${map.id}`, map);
  }

  deleteRouteMap(id: number): Observable<RouteMap> {
    return this.http.delete<RouteMap>(`${environment.apiRoutes.routeMaps}${id}`);
  }
}
