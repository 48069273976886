import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ProgramOrigin } from '../store/program-origin/program-origin.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramOriginApiService {
  constructor(private http: HttpClient) {}

  getAllProgramOrigins(): Observable<ProgramOrigin[]> {
    return this.http.get<ProgramOrigin[]>(environment.apiRoutes.programOrigins);
  }
}
