import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Scheme } from '../store/scheme/scheme.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SchemeApiService {
  constructor(private http: HttpClient) {}

  getAllSchemes(): Observable<Scheme[]> {
    return this.http.get<Scheme[]>(environment.apiRoutes.schemes);
  }

  getScheme(id: string): Observable<Scheme> {
    return this.http.get<Scheme>(`${environment.apiRoutes.schemes}${id}`);
  }

  createScheme(scheme: Scheme): Observable<Scheme> {
    return this.http.post<Scheme>(environment.apiRoutes.schemes, scheme);
  }

  updateScheme(scheme: Scheme): Observable<Scheme> {
    return this.http.patch<Scheme>(`${environment.apiRoutes.schemes}${scheme.id}`, scheme);
  }

  deleteScheme(id: number): Observable<Scheme> {
    return this.http.delete<Scheme>(`${environment.apiRoutes.schemes}${id}`);
  }
}
