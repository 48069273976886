import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Route } from '../store/route/route.model';

@Injectable({
  providedIn: 'root'
})
export class RouteApiService {
  constructor(private http: HttpClient) {}

  getAllRoutes(): Observable<Route[]> {
    return this.http.get<Route[]>(environment.apiRoutes.routes);
  }
}
