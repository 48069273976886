import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Constraint } from '../store/constraints/constraints.model';

@Injectable({
  providedIn: 'root'
})
export class ConstraintApiService {
  constructor(private http: HttpClient) {}

  getAllConstraints(): Observable<Constraint[]> {
    return this.http.get<Constraint[]>(environment.apiRoutes.constraints);
  }
}
