import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Contact } from '../store/contact/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactApiService {
  constructor(private http: HttpClient) {}

  getAllContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(environment.apiRoutes.contacts);
  }
}
