import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Map } from '../store/map/map.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapApiService {
  constructor(private http: HttpClient) {}

  getAllMaps(): Observable<Map[]> {
    return this.http.get<Map[]>(environment.apiRoutes.maps);
  }

  getMap(id: number): Observable<Map> {
    return this.http.get<Map>(`${environment.apiRoutes.maps}${id}`);
  }

  createMap(map: Map): Observable<Map> {
    return this.http.post<Map>(environment.apiRoutes.maps, map);
  }

  updateMap(map: Map): Observable<Map> {
    return this.http.patch<Map>(`${environment.apiRoutes.maps}${map.id}`, map);
  }

  deleteMap(id: number): Observable<Map> {
    return this.http.delete<Map>(`${environment.apiRoutes.maps}${id}`);
  }
}
