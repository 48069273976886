import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../store/user/user.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiRoutes.users);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${environment.apiRoutes.users}${id}`);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(environment.apiRoutes.users, user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.apiRoutes.users}${user.id}`, user);
  }

  updateUserPassword(user: User): Observable<User> {
    return this.http.patch<User>(`${environment.apiRoutes.users}password/${user.id}`, user)
  }

  deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(`${environment.apiRoutes.users}${id}`);
  }
}
