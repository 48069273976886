import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Origin } from '../store/origin/origin.model';

@Injectable({
  providedIn: 'root'
})
export class OriginApiService {
  constructor(private http: HttpClient) {}

  getAllOrigins(): Observable<Origin[]> {
    return this.http.get<Origin[]>(environment.apiRoutes.origins);
  }
}
